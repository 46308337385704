import { useState } from 'react';
import Slider, { Settings } from 'react-slick';

interface Props extends React.HtmlHTMLAttributes<HTMLDivElement> {
  settings?: Settings;
  type?: string;
}

const defaultSettings: Settings = {
  infinite: true,
  speed: 2000,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 5000,
  dots: true,
};

const Carousel: React.FC<Props> = ({ settings, type, children }) => (
  <Slider className={`carousel ${type}`} {...{ ...defaultSettings, ...settings }}>
    {children}
  </Slider>
);

export default Carousel;
